<template>
  <div :class="$style.collapse">
    <transition
      :name="$style.collapse"
      @enter="onEnter"
      @after-enter="onEnterTo"
    >
      <div ref="content" v-if="isOpen">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue'

defineOptions({
  name: 'UiCollapse'
})

const props = withDefaults(
  defineProps<{
    value?: boolean
  }>(),
  {
    value: false
  }
)

const isOpen = computed(() => props.value)

const content = ref<HTMLElement | null>(null)

const tryToSetContentHeight = () => {
  if (content.value) {
    content.value.style.height = content.value.scrollHeight + 'px'
  }
}

watch(isOpen, val => {
  if (!val) {
    tryToSetContentHeight()
  }
})

const onEnter = () => {
  tryToSetContentHeight()
}

const onEnterTo = () => {
  if (content.value) {
    content.value.style.removeProperty('height')
  }
}
</script>

<style lang="scss" module>
.collapse {
  transition: margin-top 0.4s ease;
  &:global(-leave-active),
  &:global(-enter-active) {
    transition:
      0.4s opacity ease,
      0.4s height ease;
    overflow: hidden;
  }
  &:global(-enter-active),
  &:global(-leave-to) {
    opacity: 0;
    height: 0;
  }
  &:global(-enter-to) {
    opacity: 1;
  }
  &:global(-leave-to) {
    height: 0 !important;
  }
}
</style>
